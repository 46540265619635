/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import 'bootstrap/scss/bootstrap';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";



// =========================================Global Design===========================

html,
body {
    font-family: "DM Sans", sans-serif;
    background-color: #f5f5f5;
}

.head-title h1 {
    background: #99b1b3;
    color: #ffffff;
    padding: 20px 10px;
    font-weight: 500 !important;
}

// --------------------------------fonts------------------------------
.fs-40 {
    font-size: 40px;
}

.fs-20 {
    font-size: 20px;
}

.u-center-text {
    text-align: center;
}

// -------------------------------heading--------------------------------
.heading--primary {
    font-weight: 700;
    color: #008481;
    font-size: 62px;
}

@media (max-width: 768px) {
    .heading--primary {
        font-size: 42px;
    }
}

.heading--secondary {
    font-weight: 700;
    color: #003a40;
    font-size: 38px;
    line-height: 1.3;
}

@media (max-width: 768px) {
    .heading--secondary {
        font-size: 28px;
    }
}

.heading--secondary span {
    color: #008481;
}

.heading--secondary .color-blue {
    color: #0079bd;
}

.heading--secondary--color-white {
    color: #ffffff;
}

.heading--secondary--color-blue {
    color: #0079bd;
}

.heading--tertiary {
    font-weight: 500;
    color: #003a40;
    font-size: 32px;
}

.heading--tertiary--font-weight-700 {
    font-weight: 700;
}

@media (max-width: 768px) {
    .heading--tertiary {
        font-size: 22px;
    }
}

.heading--tertiary span {
    color: #008481;
    font-weight: 600;
}

.heading--fourth {
    color: #003a40;
    font-weight: 500;
    font-size: 24px;
}

@media (max-width: 768px) {
    .heading--fourth {
        font-size: 22px;
    }
}

.heading--fourth--back-green {
    background-color: #008481;
    color: #ffffff;
    padding: 23px 40px;
}

.heading--fourth--font-weight-700 {
    font-weight: 700;
}

.heading--fourth--font-weight-600 {
    font-weight: 600;
}

.heading--fourth small {
    font-size: 14px;
    color: #003a40;
}

.heading--fourth strong {
    margin-right: 25px;
}

@media (max-width: 992px) {
    .heading {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .heading--primary {
        font-size: 42px;
    }

    .heading--secondary {
        font-size: 28px;
    }

    .heading--fourth {
        font-size: 22px;
    }

    .total-cost__heading-box span {
        font-size: 10px;
    }

    .total-cost__heading-box h4 {
        font-size: 10px;
    }
}

// ---------------------------------Border------------------------------------
.no-border {
    border: none;
}

.no-radius {
    border-radius: 0px;
}

.radius-05 {
    border-radius: 5px;
}

.radius-1 {
    border-radius: 10px;
}

.radius-2 {
    border-radius: 20px;
}

.radius-3 {
    border-radius: 30px;
}

// ===============================padding=======================================

.custom_padding {
    padding-top: 84px;
    // padding-bottom: 24px;
}

// ---------------------------------------------OWL-Carousel-----------------------------
.owl-carousel .owl-item img {
    border-radius: 4px !important;
}

#landingOWL {
    @media (min-width:1024px) {
        .owl-theme .owl-nav.disabled+.owl-dots {
            position: absolute;
            top: 100%;
            left: 50%;
        }

    }

    .owl-theme .owl-dots .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
        background-color: #003a40;
    }

    .owl-theme .owl-dots .owl-dot span {
        background-color: transparent;
        border: 1px solid #003a40;
    }

}

@media (min-width:1024px) {
    .owl-theme .owl-nav.disabled+.owl-dots {
        position: absolute;
        top: 296px;
        left: 50%;
    }

}

@media (max-width:1024px) {
    .owl-theme .owl-nav.disabled+.owl-dots {
        position: absolute;
        top: 193px;
        left: 50%;
    }

}

.owl-theme .owl-dots .owl-dot span {
    width: 9px;
    height: 9px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background-color: white;
}

.owl-theme .owl-dots .owl-dot span {
    background-color: transparent;
    border: 1px solid white;
}

.owl-theme .owl-dots .owl-dot {
    width: 15px;
}

// -------------------------------------buttons---------------------------------------

.btn-primary {
    background-color: #f1c400;
    color: #003a40 !important;
    border: 2px solid #f1c400;
}

.btn-primary:hover,
.btn-primary:first-child:active .btn-primary:active {
    background-color: #f1c400 !important;
    color: #003a40 !important;
    border: 2px solid #f1c400 !important;
    box-shadow: 0 0 0 2.5px rgba(241, 196, 0, 0.25) !important;
}

.btn-primary .btn-check:checked+.btn,
.btn-primary .btn.active,
.btn-primary .btn.show,
.btn-primary .btn:first-child:active,
.btn-primary :not(.btn-check)+.btn:active {
    background-color: #f1c400 !important;
    color: #003a40 !important;
    box-shadow: 0 0 0 2.5px rgba(241, 196, 0, 0.25) !important;
    border: 2px solid #f1c400 !important;
}

.btn-primary:focus-visible {
    background-color: #f1c400 !important;
    color: #003a40 !important;
    box-shadow: 0 0 0 2.5px rgba(241, 196, 0, 0.25) !important;
    border: 2px solid #f1c400 !important;
}

.btn-primary:active {
    background-color: #f1c400 !important;
    color: #003a40 !important;
    box-shadow: 0 0 0 2.5px rgba(241, 196, 0, 0.25) !important;
    border: 2px solid #f1c400 !important;
}

.btn-primary--border {
    background-color: #ffffff;
    border: 2px solid #f1c400;
}

// ===============================Primng Elements ==========================================

// -----------------------------------calender-------------------

#homeCalender {
    .p-datepicker-calendar {
        color: white;
        min-height: 160px !important;
        height: 160px !important;
        width: 200px;
    }

    .p-datepicker-calendar thead {
        font-size: 12px;
    }

    .p-datepicker-calendar tbody {
        font-size: 10px;
    }

    #pn_id_1_panel {
        background-color: #99B0B3 !important;
    }

    .p-datepicker {
        background-color: #99B0B3;
    }

    .p-datepicker table td.p-datepicker-today>span {
        background: #F0AD00 !important;
        color: white !important;
        border-color: transparent;
        height: 25px !important;
    }

    .p-datepicker table {
        border-spacing: 1px;
        margin: auto;
    }

    .p-datepicker table td {
        padding: 0;
    }

    .p-datepicker table td>span {
        width: 25px;
        height: 35px;
    }

    .p-datepicker .p-datepicker-header {
        background-color: transparent;
        color: white;
        border: 0;
        padding: 16px 0px;
    }

    button:not(:disabled),
    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled) {
        color: white;
    }

    .p-calendar {
        width: 100%;
        height: 100% !important;
    }

    .p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
        height: 25px !important;
        color: #003a40;
    }

}

#landingCalender {
    .p-datepicker {
        border: 0px !important;
        border-radius: 4px;
    }

    .p-datepicker-calendar {
        // color: rgba(0, 132, 129, 1) !important;
        color: #003A40;
        font-size: 14px;
        font-weight: 400;
    }

    .p-datepicker table td.p-datepicker-today>span {
        // background: rgba(0, 132, 129, 1) !important;
        background-color: #51b2a8;
        ;
        color: white !important;
        border-color: transparent;
        height: 25px !important;
    }

    .p-datepicker table td>span {
        width: 25px;
        height: 25px;
    }

    .p-datepicker table {
        border-spacing: 1px;
        margin: auto;
    }


    button:not(:disabled),
    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled) {
        color: #003A40 !important;
    }
}

// ==========================================================================================

// -------------------scroll bar design ---------------------------------
@media (min-width: 768px) {
    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 15px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background: rgba(0, 132, 129, 1);
    }

    .cursor-pointer {
        cursor: pointer;
    }

    * {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 132, 129, 1) #fff;
        scrollbar-shadow-color: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}

// ----------------------width------------------------------------------

.u-width-70 {
    width: 70%;
}

@media (max-width: 992px) {
    .u-width-70 {
        width: 100%;
    }
}

.u-width-100 {
    width: 100%;
}

@media (max-width: 992px) {
    .u-width-100 {
        width: 50%;
    }
}

// =====================margins===========================

.u-margin-bottom-small {
    margin-bottom: 16px;
}

.u-margin-bottom-medium {
    margin-bottom: 20px;
}

.u-margin-bottom-medium--3 {
    margin-bottom: 30px;
}

.u-margin-bottom-large {
    margin-bottom: 58px;
}

@media (max-width: 768px) {
    .u-margin-bottom-large {
        margin-bottom: 30px;
    }
}

.u-margin-top-small {
    margin-top: 15px;
}

.u-margin-top-medium {
    margin-top: 30px;
}

.u-margin-top-large {
    margin-top: 58px;
}

@media (max-width: 768px) {
    .u-margin-top-large {
        margin-top: 30px;
    }
}

.u-margin-right-small {
    margin-right: 20px;
}

.u-margin-right-medium {
    margin-right: 50px;
}

.u-margin-right-large {
    margin-right: 133px;
}

.u-margin-between-large {
    margin: 0 150px;
}

.u-postion-relative {
    position: relative;
}

// ====================================padding===============================
.u-padding-4 {
    padding: 40px;
}

.u-height-100 {
    height: 100%;
}

.u-padding-5 {
    padding: 50px;
}

@media (min-width:1024px) {
    .container-fluid {
        padding-left: 8px;
        padding-right: 8px;
    }

}

// ==================================gaps=======================================

.gap-32 {
    gap: 2rem;
}

// ===============================pagination=======================================

ul.ngx-pagination {
    margin: 0 !important;
    padding: 0 !important;
}

.ngx-pagination .current {
    background-color: #003a40 !important;
    border-radius: 6px;
    font-weight: 500;
}

.ngx-pagination a {
    text-decoration: none !important;
    color: #003a40 !important;
    font-weight: 500;
}

/**------------------------------------------------------------------------
 *                           SWAL
 *------------------------------------------------------------------------**/
.swal2-styled.swal2-confirm {
    background-image: linear-gradient(to left, #006d7b, #1fa397) !important;
    color: white !important;
    border-radius: 8px !important;
    height: 44px !important;
    background-color: #006d7b !important;
    min-width: 100px;

    &:focus {
        box-shadow: none;
    }

    font-size: 18px;
}

.swal2-styled.swal2-deny {
    border-radius: 28px !important;
    height: 56px !important;
    padding: 12px 40px !important;
}

.swal2-styled.swal2-cancel {
    color: #003A40 !important;
    border-radius: 28px !important;
    background-color: white !important;
    border: 1px solid #003A40 !important;
    height: 56px !important;

    &:hover {
        color: #003A40 !important;
        border-radius: 28px !important;
        background-color: white !important;
        border: 1px solid #003A40 !important;
    }
}

#swal2-title {
    color: #1c9287;

    @media (min-width: 800px) {
        font-size: 24px;
    }
}

.swal2-icon.swal2-error {
    background-color: #e23737;
    border-color: #e23737 !important;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
    background-color: white !important;
}

.swal2-icon--success__line {
    background-color: #1c9287 !important;
}

.swal2-icon--success__ring {
    border-color: #1c9287 !important;
}

.swal-title {
    color: #1c9287 !important;
}

.swal-text {
    color: #1c9287 !important;
}

span.swal2-success-line-tip,
span.swal2-success-line-long {
    background-color: #16928f !important;
}

.swal2-success {
    background-color: #16928f !important;
}

.swal-footer {
    display: flex;
    justify-content: center;
}

// ==================================chat==================================
// iframe {

//     .css-eqo1no {
//         display: flex;
//         background: white;
//         height: 80vh;
//         flex-direction: column;
//         -webkit-box-pack: justify;
//         justify-content: space-between;
//         border: 0.5px solid rgb(216, 216, 216);
//         border-radius: 10px;
//         background-color: white !important;
//         opacity: 1 !important;
//     }
// }

.sectionbody {
    border-radius: 4px;
    min-height: 201px;
    background-color: white;

    .icon {
        @media (min-width:1025px) {

            height: 290px;
        }

        @media (max-width: 991.98px) {

            height: 190px;
        }

        .news-img {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        overflow: hidden;
    }

    .caption {
        background-color: #99E0DC;
        padding: 32px 14px;
        padding-bottom: 14px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        // .title {
        //     font-weight: 500;
        //     font-size: 40px;
        //     margin-top: 2px;
        //     margin-bottom: 4px;
        // }

        .subTitle {
            font-weight: 300;
            color: #003A40;
            font-size: 16px;
            margin-bottom: 6px;
        }

        .discription {
            color: #003A40;
            font-size: 12px;
            font-weight: 300;
            margin-bottom: 0;
        }

    }
}

@media (max-width:700px) {
    .img-fluid {
        height: 100%;
    }
}

.sectionbody-landing {
    border-radius: 6px !important;
    overflow: hidden;

    .caption-landing {
        background-color: white !important;

        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;

        @media (max-width:900px) {
            padding-bottom: 8px;
        }
    }

    .news-landing-img {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px !important;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
    }

    .discription {
        width: 90%;
        font-size: 14px !important;
    }

    .subTitle {
        font-size: 16px;
        font-weight: 500;
    }

    .icon {
        @media (min-width:900px) {
            width: 65%;

        }

        @media (max-width:900px) {
            width: 100%;

        }
    }

    .title {
        font-weight: 900;
        font-size: 18px;
    }
}
#text-example-modal-xl {
    .title {
            font-weight: 900;
            font-size: 18px;
        }
}
.p-dropdown{
    background-color: transparent;
    .p-dropdown-items{
        padding:2px 8px;
    }

    padding: 2px 8px;
    .p-dropdown-label{
            padding: 2px 8px;
    }

        .p-dropdown-panel{
            background-color: #ededed;;
        }
}

